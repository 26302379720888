import { FC } from 'react'
import styled from 'styled-components'
import { BannerI } from '../../interfaces'
import { BREAKPOINTS } from '../../utils/constants/layout'

/**
 * Banner Component
 * @return {React.JSX.Element}
 */
const BannerComponent: FC<BannerI> = ({
    isVisible,
    url,
    images
}): React.JSX.Element => {
    const { MD, SM } = BREAKPOINTS

    return isVisible ? (
        <BannerContainer>
            {url}
            <picture>
                <source
                    srcSet={images.mobile.url}
                    media={`(max-width: ${SM}px)`}
                />
                <source
                    srcSet={images.tablet.url}
                    media={`(min-width: ${SM}px) and (max-width: ${MD}px)`}
                />
                <img
                    src={images.desktop.url}
                    style={{ width: '100%' }}
                    alt='Banner JOTA INFO'
                />
            </picture>
        </BannerContainer>
    ) : (
        <></>
    )
}

const BannerContainer = styled.div``

export default BannerComponent
