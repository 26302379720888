import Link from 'next/link'
import { FC, useEffect, useState } from 'react'

import {
    HeaderContainerBase,
    MenuIconHover,
    SearchContainer
} from './Header.styled'
import {
    LogoJotaHeader,
    AccessibilityComponent,
    InputSearchComponent
} from './Header.utils.components'
import { UserLoggedBtn } from './UserBtnLogged'
import { ProfileAPI } from '@App/api/api'
import { AppDispatch, AuthState } from '../../../api/types/auth'
import { useDispatch } from 'react-redux'
import { sidebarToogle } from '@App/api/ducks/ui'
import useScrollPosition from '@App/libs/hooks/useScroll'
import HeadMetaComponent from './Head.component'
import { metadataType } from '@App/pages/content/pro/[vertical]/[category]/[slug]'

declare const window: Window & { dataLayer: Record<string, unknown>[] }

interface HeaderBaseI {
    auth?: AuthState
    isHeader?: boolean
    showAcessibility?: boolean
    pageTributos?: boolean
    scroll?: boolean
}
const SCROLL_LIMIT_NORMAL = 65

const getDataFromBackoffice = (token: any[]) => {
    fetch(ProfileAPI.me(), {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`
        }
    })
        .then((response) => response.json())
        .then((success) => {
            const OUTPUT_USER = {
                id: success.id,
                user_id: success.id,
                isProUser:
                    success.profile.has_poder ||
                    success.profile.has_tributos ||
                    (success.profile.has_saude && true),
                company: success.profile.company_name,
                companyTier: success.profile.company_tier,
                isStudent: success.profile.is_student,
                planos: success.profile.plan,
                paying_user: success.profile.paying_user,
                created_at: success.created_at,
                redirected_user: false
            }

            const getRedirectCookie = () => {
                const cookies = document.cookie.split(';')
                const redirect = cookies.find((cookie) =>
                    cookie.trim().startsWith('isBetaMqlV2=1')
                )
                OUTPUT_USER.redirected_user = redirect ? true : false
            }

            getRedirectCookie()

            localStorage.setItem('JOTA_USER', JSON.stringify(OUTPUT_USER))
            localStorage.setItem(
                'JOTA_USER_TIMESTAMP',
                `${new Date().toDateString()}`
            )

            window.gtag('event', 'JOTA_DATA', JSON.stringify(OUTPUT_USER))
            window.gtag('event', 'jota_bko_event')
            window.dataLayer.push({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                JOTA_USER: JSON.parse(localStorage.getItem('JOTA_USER')!)
            })
        })
        .catch((error) => {
            console.log('ERROR ->', error)
        })
}

const putDataInDatalayer = () => {
    const storedUser = localStorage.getItem('JOTA_USER')

    if (storedUser) {
        window.gtag('event', 'JOTA_DATA', JSON.parse(storedUser))
        window.gtag('event', 'jota_bko_event')
        window.dataLayer.push({
            JOTA_USER: JSON.parse(storedUser)
        })
    } else {
        setTimeout(() => {
            if (localStorage.getItem('JU_TOKEN')) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const token = JSON.parse(
                    localStorage.getItem('JU_TOKEN')!
                ).idToken
                getDataFromBackoffice(token)
            }
        }, 2000)
    }
}

const cleanBackofficeDataFromStorage = () => {
    if (localStorage.getItem('JOTA_USER_TIMESTAMP')) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const storedTimestamp = new Date(
            localStorage.getItem('JOTA_USER_TIMESTAMP')!
        )
        const currentDate = new Date()
        const diffInMilliseconds =
            currentDate.getTime() - storedTimestamp.getTime()
        const daysDifference = Math.floor(
            diffInMilliseconds / (1000 * 60 * 60 * 24)
        )

        if (daysDifference > 5) {
            localStorage.removeItem('JOTA_USER')
            localStorage.removeItem('JOTA_USER_TIMESTAMP')
            return
        }
    }
}

const HeaderBase: FC<HeaderBaseI> = ({
    showAcessibility = true,
    pageTributos = false
}) => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || []
            putDataInDatalayer()
            cleanBackofficeDataFromStorage()
        }
    }, [])

    const dispatch = useDispatch<AppDispatch>()
    const scrollPosition = useScrollPosition()

    const [metadata] = useState<metadataType>({
        title: '',
        description: '',
        url: '',
        keywords: ''
    })

    return (
        <HeaderContainerBase
            pageTributos={pageTributos}
            scroll={scrollPosition > SCROLL_LIMIT_NORMAL}
        >
            <HeadMetaComponent {...metadata} />
            <SearchContainer>
                <MenuIconHover
                    fill='#3C3F4C'
                    onClick={() => {
                        dispatch(sidebarToogle(true)).catch(() => false)
                    }}
                    style={{ padding: '8px 10px', marginRight: '8px' }}
                />
                <Link href={'/'}>
                    <LogoJotaHeader />
                </Link>
                {!pageTributos && <InputSearchComponent />}
            </SearchContainer>
            {!pageTributos && (
                <UserLoggedBtn showUserMenu={false} isHeader={false} />
            )}
            {showAcessibility && <AccessibilityComponent />}
        </HeaderContainerBase>
    )
}

export default HeaderBase
