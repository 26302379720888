import React, { useState, useEffect, FC } from 'react'
import { CardDS } from '../..'
import { ImageJU, News } from '../../../interfaces'
import { checkSpecialOrInfoNews } from '@App/libs/utils/constants/route'
import { PATROCINADO } from '@App/libs/utils/constants/home'
import { CONTEUDO_LIBERADO } from './MiddleColumn.component'
import router from 'next/router'
import styled from 'styled-components'

export const TABLET_LIMIT = 1200

export type windowWidthType = {
    width: number
}

export const getImageOutput = (
    images: ImageJU,
    windowSize: windowWidthType
) => {
    if (typeof images == 'string') {
        return images
    }

    if (images && typeof images === 'object') {
        return windowSize.width <= TABLET_LIMIT
            ? images.tabletMobile
            : images.desktop
    }
}

/**
 * HeadlineComponent Component
 * @param {News} manchete
 * @return {React.JSX.Element}
 */
const HeadlineComponent: FC<{ manchete: News }> = ({
    manchete
}): React.JSX.Element => {
    const [screenWidth, setScreenWidth] = useState<any>()
    const [windowSize, setWindowSize] = useState<windowWidthType>({
        width: window.innerWidth
    })

    useEffect(() => {
        if (window) {
            setScreenWidth(window.screen.width)
        }

        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth
            })
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    /**
     * Get Author
     * @return {string}
     */
    const getAuthor = () => {
        if (Array.isArray(manchete?.author)) {
            const temp = manchete.author.map((author) => author.name)
            return temp.join(',')
        } else if (typeof manchete?.author == 'string') {
            return manchete.author
        }
    }

    return (
        <Container>
            <CardDS
                title={
                    manchete?.alternative?.title != ''
                        ? manchete?.alternative?.title
                        : manchete.title
                }
                text={
                    manchete?.alternative?.shortDescription != ''
                        ? manchete?.alternative?.shortDescription
                        : manchete?.excerpt
                }
                titleSearchTerm={''}
                newsLink={`${checkSpecialOrInfoNews(
                    manchete.categories,
                    manchete.slug,
                    manchete.permalink
                )}`}
                image={
                    manchete.images &&
                    getImageOutput(manchete.images, windowSize)
                }
                size='large'
                tagLabel={
                    manchete?.alternative.hat != ''
                        ? manchete?.alternative.hat
                        : manchete?.hat
                }
                tagDisableHover
                authorTagLabel={getAuthor()}
                isVertical
                type={screenWidth < 500 ? 'bleeding' : 'normal'}
                hasSeparator={screenWidth < 1199}
                multipleAuthors={
                    Array.isArray(manchete?.author) ? manchete?.author : []
                }
                authorTagOnClick={(authorUrl) => {
                    if (authorUrl) {
                        return router.push(`autor/${authorUrl}`)
                    }
                }}
                subjectTagLabel={
                    manchete.inherits_from_PRO ? CONTEUDO_LIBERADO : undefined
                }
                subjectTagDisableHover
                isSponsored={
                    (manchete.type === PATROCINADO ||
                        manchete.inherits_from_PRO) ??
                    false
                }
            />
        </Container>
    )
}

export default HeadlineComponent

const Container = styled.div``
