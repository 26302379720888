import { BannerTop } from '@App/libs/interfaces/global.interface'
import { addDatalayer } from '@App/libs/utils/geral'
import { FC } from 'react'
import styled from 'styled-components'

const BannerTopComponent: FC<BannerTop> = ({ image, url, scroll }) => {
    return (
        <ContainerBanner
            target='_blank'
            scroll={scroll}
            onClick={() => {
                addDatalayer({
                    event: 'clique_banner_top',
                    conteudo: 'banner_top',
                    link: url
                })
                window.open(url, '_blank')
            }}
        >
            <picture>
                <source srcSet={image.mobile} media='(max-width: 527px)' />
                <source
                    srcSet={image.tablet}
                    media='(min-width: 528px) and (max-width: 900px)'
                />
                <ImageBase src={image.desktop} alt='Banner Top JOTA INFO' />
            </picture>
        </ContainerBanner>
    )
}

export default BannerTopComponent

const ContainerBanner = styled.a<{ scroll?: boolean }>`
    ${(props) => props.scroll && `display: none;`}
    &:hover {
        cursor: pointer;
    }
`

const ImageBase = styled.img`
    width: 100%;
`
