const staticFooterMenu = [
    {
        columnTitle: 'Poder PRO',
        columnItens: [
            {
                title: 'Apostas da Semana',
                url: '/poder/apostas-da-semana',
                openInNewTab: false
            },
            {
                title: 'Impacto nas Instituições',
                url: '/poder/impacto-nas-instituicoes',
                openInNewTab: false
            },
            {
                title: 'Risco Político',
                url: '/poder/risco-politico',
                openInNewTab: false
            }
        ]
    },
    {
        columnTitle: 'Tributos PRO',
        columnItens: [
            {
                title: 'Apostas da Semana',
                url: '/tributos/apostas-da-semana',
                openInNewTab: false
            },
            {
                title: 'Direto do CARF',
                url: '/tributos/direto-do-carf',
                openInNewTab: false
            },
            {
                title: 'Direto da Corte',
                url: '/tributos/direto-da-corte',
                openInNewTab: false
            },
            {
                title: 'Direto do Legislativo',
                url: '/tributos/direto-do-legislativo',
                openInNewTab: false
            },
            {
                title: 'Matinal',
                url: '/tributos/matinal',
                openInNewTab: false
            },
            {
                title: 'Relatórios Especiais',
                url: '/tributos/relatorio-especial',
                openInNewTab: false
            }
        ]
    },
    {
        columnTitle: 'Editorias',
        columnItens: [
            {
                title: 'Executivo',
                url: '/poder/executivo',
                openInNewTab: false
            },
            {
                title: 'Legislativo',
                url: '/poder/legislativo',
                openInNewTab: false
            },
            {
                title: 'STF',
                url: '/poder/stf',
                openInNewTab: false
            },
            {
                title: 'Justiça',
                url: '/poder/justica',
                openInNewTab: false
            },
            {
                title: 'Saúde',
                url: '/saude',
                openInNewTab: false
            },
            {
                title: 'Opinião e Análise',
                url: '/opiniao-e-analise',
                openInNewTab: false
            },
            {
                title: 'Coberturas Especiais',
                url: '/coberturas-especiais',
                openInNewTab: false
            },
            {
                title: 'Eleições 2024',
                url: '/eleicoes',
                openInNewTab: false
            }
        ]
    },
    {
        columnTitle: 'Sobre o JOTA',
        columnItens: [
            {
                title: 'Estúdio JOTA',
                url: `https://conteudo.jota.info/estudio-jota`,
                openInNewTab: true
            },
            {
                title: 'Ética JOTA',
                url: `${process.env.NEXT_PUBLIC_JOTAINFO}/etica-jota`,
                openInNewTab: true
            },
            {
                title: 'Política de Privacidade',
                url: `${process.env.NEXT_PUBLIC_JOTAINFO}/politica-de-privacidade`,
                openInNewTab: true
            },
            {
                title: 'Seus Dados',
                url: `https://app.dadoslegais.com.br/jota/ `,
                openInNewTab: true
            },
            {
                title: 'Termos de Uso',
                url: `${process.env.NEXT_PUBLIC_JOTAINFO}/termos-de-uso`,
                openInNewTab: true
            }
        ]
    }
]

export default staticFooterMenu
